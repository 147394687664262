import axios from "axios";
const state = {
    loader:true,
    data:[],
    meta:{
        total:0
    },
    filters:{},
    filters_length:0
};

const getters = {};

const actions = {
    getData( {commit, state}, options){
        state.loader = true
        commit('setOptions', options)
        const { sortBy, sortDesc, page, itemsPerPage } = options
        let filterParams = {};
        Object.keys(state.filters).forEach(key => {
            const value = state.filters[key];
            if (Array.isArray(value) && value.length > 0) {
                filterParams[`filter[${key}]`] = value.join(",");
            } else if (value !== "" && value !== null && value !== undefined) {
                filterParams[`filter[${key}]`] = value;
            }
        });
        state.filters_length = Object.keys(Object.assign({}, filterParams)).length
        console.log(filterParams)
        let params = {
            page,
            per_page: itemsPerPage,
            sort: sortDesc[0] ? `-${sortBy[0]}` : sortBy[0],
            ...filterParams,
        }
        axios.get(process.env.VUE_APP_BACKEND_ROUTE + "api/v2/inventories", { params } ).then(response => {
            commit('setData', response);
        }).catch((error) => {
            console.error('Error:', error)
        }).finally(() => (state.loader = false))
    },
    updateFilters({ commit, dispatch, state }, filters) {
        commit("setFilters", filters);
        state.options.page = 1;
        dispatch("getData", state.options);
    },
    cleanFilter({ commit, dispatch, state }) {
        commit("cleanFilter");
        state.options.page = 1;
        dispatch("getData", state.options);
    },
};

const mutations = {
    setData(state, response){
        const { data, ...meta } = response.data
        state.data = data;
        state.meta = meta
    },
    setFilters(state, filters) {
        Object.keys(filters).forEach(key => {
            if (filters[key] === "" || filters[key] === null || filters[key] === undefined) {
                delete state.filters[key];
            } else {
                state.filters[key] = filters[key];
            }
        });
    },
    setOptions(state, options){
        state.options = options
    },
    cleanFilter(state) {
        state.filters = {};
        state.filters_length = 0;
    },
};

export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}